"use client"

import React, { useState } from "react"
import Link from "next/link"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  ArrowRight,
  Check,
  Hammer,
  Loader2,
  Mail,
  MapPin,
  Network,
  Phone,
  User,
} from "lucide-react"
import { useForm } from "react-hook-form"
import { boolean, object, string, infer as zInfer, array, number } from "zod"

import { submitFormConsultation } from "@/lib/client-api/submit.client"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { ConsultationFormPropsType } from "./ConsultationFormLazy"
import FormAddressInput from "./FormAddressInput"
import IncludeTrackerArray from "./IncludeTrackerArray"
import { cn } from "@/lib/utils"

const regex = {
  phone: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  zip: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
}

export const ConsulationFormSchema = object({
  name: string().min(1, { message: "This field has to be filled." }),
  phone: string()
    .min(1, { message: "This field has to be filled." })
    .regex(regex.phone, "Invalid phone number."),
  email: string()
    .min(1, { message: "This field has to be filled." })
    .email("This is not a valid email."),
  address: string().min(1, { message: "This field has to be filled." }),
  addressObj: object({
    address: string().min(1, { message: "This field has to be filled." }),
    city: string().min(1, { message: "This field has to be filled." }),
    state: string().min(1, { message: "This field has to be filled." }),
    zip: string().min(1, { message: "This field has to be filled." }),
  }),
  details: string().optional(),
  source: string().optional(),
  comment: string().optional(),
  dealerOverrideId: string().optional(),
  smsConsent: boolean().optional(),
  trackerArray: array(object({id: number(), created_at: string()})).optional(),
})

export default function ConsultationForm({
  dealerOverride,
  customStyle,
}: ConsultationFormPropsType) {
  const form = useForm<zInfer<typeof ConsulationFormSchema>>({
    defaultValues: {
      dealerOverrideId: dealerOverride?.id
        ? dealerOverride.id.toString()
        : undefined,
    },
    resolver: zodResolver(ConsulationFormSchema),
  })
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<zInfer<typeof ConsulationFormSchema> | null>(
    null
  )


  async function onSubmit(data: zInfer<typeof ConsulationFormSchema>) {
    setLoading(true)
    await submitFormConsultation({ form: data })
    setLoading(false)
    setData(data)
  }

  const defaultVariant = {
    name: '',
    phone: '',
    email: '',
    address: '',
    details: '',
    source: '',
    comment: '',
    checkbox: '',
    consent: '',
  }
  const propStyle = customStyle ?? defaultVariant

  if (data) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-4">
            Submission Received <Check className="text-flame-500 size-6" />
          </CardTitle>
          <CardDescription>
            We’ve received your request for an offer and free consultation.
            Someone will be contacting you soon!
          </CardDescription>
        </CardHeader>
        <CardContent className="text-carbon-400 flex flex-col gap-2">
          <p className={"flex items-center gap-2"}>
            <User className="size-4" />
            {data.name}
          </p>
          <p className={"flex items-center gap-2"}>
            <Phone className="size-4" />
            {data.phone}
          </p>
          <p className={"flex items-center gap-2"}>
            <Mail className="size-4" />
            {data.email}
          </p>
          <p className={"flex items-center gap-2"}>
            <MapPin className="size-4" />
            {data.address}
          </p>
          {data.details && (
            <p className={"flex items-center gap-2"}>
              <Hammer className="size-4" />
              {data.details}
            </p>
          )}
          {data.source && (
            <p className={"flex items-center gap-2"}>
              <Network className="size-4" />
              {data.source}
            </p>
          )}
          {data.comment && (
            <p className={"flex items-center"}>{data.comment}</p>
          )}
        </CardContent>
        {/* <CardFooter>
          <p>Card Footer</p>
        </CardFooter> */}
      </Card>
    )
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid grid-cols-2 gap-4"
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="col-span-1">
              <FormLabel className={propStyle.name}>Name</FormLabel>
              <FormControl>
                <Input
                  type={"text"}
                  required
                  placeholder="enter your name *"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem className="col-span-1">
              <FormLabel className={propStyle.phone}>Phone</FormLabel>
              <FormControl>
                <Input
                  required
                  type={"text"}
                  placeholder="enter your phone *"
                  mask={"phone"}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="col-span-1">
              <FormLabel className={propStyle.email}>Email</FormLabel>
              <FormControl>
                <Input
                  type={"email"}
                  required
                  placeholder="enter your email *"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => <FormAddressInput form={form} field={field} propStyle={propStyle.address} />}
        />
        <FormField
          control={form.control}
          name="details"
          render={({ field }) => (
            <FormItem className="col-span-1">
              <FormLabel className={propStyle.details}>Project size & savings</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="text-left">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent
                  align="start"
                  className="max-w-[calc(2_*_var(--radix-select-trigger-width))]"
                >
                  <SelectItem value="15+ Doors">
                    15+ Doors (Best Pricing)
                  </SelectItem>
                  <SelectItem value="10-14 Doors">
                    10-14 Doors (Better Pricing)
                  </SelectItem>
                  <SelectItem value="4-9 Doors">
                    4-9 Doors (Good Pricing)
                  </SelectItem>
                  <SelectItem value="Organizer">
                    Closet Organizer Systems
                  </SelectItem>
                  <SelectItem value="Interior/Closet Doors + Organizer">
                    Interior & Closet Doors, Closet Organizer Systems
                  </SelectItem>
                </SelectContent>
              </Select>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="source"
          render={({ field }) => (
            <FormItem className="col-span-1">
              <FormLabel className={propStyle.source}>{"How'd "}you find us?</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent
                  align="start"
                  className="w-fit min-w-[var(--radix-select-trigger-width)]"
                >
                  <SelectItem value="TV">TV</SelectItem>
                  <SelectItem value="Magazine">Magazine</SelectItem>
                  <SelectItem value="Internet Search">
                    Internet Search
                  </SelectItem>
                  <SelectItem value="Social Media">Social Media</SelectItem>
                  <SelectItem value="Friends or Family">
                    Friends or Family
                  </SelectItem>
                  <SelectItem value="Newspaper">Newspaper</SelectItem>
                  <SelectItem value="Awards">Awards</SelectItem>
                  <SelectItem value="Other">Other</SelectItem>
                </SelectContent>
              </Select>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="comment"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel className={propStyle.comment}>Tell us about your project</FormLabel>
              <FormControl>
                <Textarea className="resize-none" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="smsConsent"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel className={cn("flex flex-row items-center gap-4 px-4 border border-carbon-100 rounded py-2", propStyle.consent)}>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    className={propStyle.checkbox}
                  />
                </FormControl>
                <p className={cn("md:text-[13px] text-carbon-500 mt-0", propStyle.consent)}>
                  I agree to receive text communications regarding my
                  consultation.{" "}
                  <Link
                    href="/legal/sms-policy"
                    target={"_blank"}
                    className="text-carbon-400 md:text-[12px] ml-auto"
                  >
                    Our SMS Policy
                  </Link>
                </p>
              </FormLabel>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="trackerArray"
          render={() =>  <IncludeTrackerArray form={form}/>}
        />
        <Button
          type="submit"
          disabled={loading}
          variant={"flame"}
          className="flex gap-2 items-center w-fit whitespace-nowrap"
        >
          Submit
          {loading ? (
            <Loader2 className="size-4" />
          ) : (
            <ArrowRight className="size-4" />
          )}
        </Button>
      </form>
    </Form>
  )
}
