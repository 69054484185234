import * as React from "react"
import InputMask from "react-input-mask"

import { cn } from "@/lib/utils"

export const MASK_TYPE = {
  phone: "(999) 999-9999",
  zipCode: "99999-999",
  date: "99/99/9999",
  time: "99:99",
  creditCard: "9999 9999 9999 9999",
} as const

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  mask?: keyof typeof MASK_TYPE
}

export const defaultInputClasses =
  "flex h-10 w-full rounded-sm border border-carbon-100 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-carbon-500 placeholder:text-xs md:placeholder:text-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-carbon-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-carbon-800 dark:border-carbon-800 dark:bg-carbon-950 dark:ring-offset-carbon-950 dark:placeholder:text-carbon-400 dark:focus-visible:ring-carbon-300"

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, mask, ...props }, ref) => {
    if (mask) {
      return (
          <InputMask
            type={type}
            className={cn(defaultInputClasses, className)}
            mask={MASK_TYPE[mask]}
            {...props}
          />
      )
    } else {
      return (
        <input
          type={type}
          className={cn(defaultInputClasses, className)}
          ref={ref}
          {...props}
        />
      )
    }
  }
)
Input.displayName = "Input"

export { Input }
