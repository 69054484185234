import mixpanel from "mixpanel-browser"
import { infer as zInfer } from "zod"

import { ProFormSchema } from "@/components/forms/become-a-pro/ProForm"
import { ConsulationFormSchema } from "@/components/forms/consultation/ConsultationForm"

export async function submitFormConsultation({
  form,
}: {
  form: zInfer<typeof ConsulationFormSchema>
}) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL ?? "")
  mixpanel.track("registered_consultation", form)
  mixpanel.identify(form.phone.replace(/\D/g, ""))
  mixpanel.people.set({
    Name: `${form.name}`,
    Email: form.email,
    Phone: form.phone,
    ...form,
  })
  const res = await fetch("/api/submit/consultation", {
    method: "POST",
    body: JSON.stringify({ form }),
  })

  return await res.json()
}

export async function submitFormBecomeAPro({
  form,
}: {
  form: zInfer<typeof ProFormSchema>
}) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL ?? "")
  mixpanel.track("registered_pro", form)
  mixpanel.identify(form.phone.replace(/\D/g, ""))
  mixpanel.people.set({
    Name: `${form.name}`,
    Email: form.email,
    Phone: form.phone,
    ...form,
  })
  const res = await fetch("/api/submit/become-a-pro", {
    method: "POST",
    body: JSON.stringify({ form }),
  })

  return await res.json()
}
