import { useCallback, useEffect, useState } from "react"
import { Place } from "@googlemaps/google-maps-services-js/dist/common"
import { PlaceAutocompleteResult } from "@googlemaps/google-maps-services-js/dist/places/autocomplete"
import { Portal } from "@radix-ui/react-popover"
import { Loader2, MapPin } from "lucide-react"
import { ControllerRenderProps, UseFormReturn } from "react-hook-form"
import { useHotkeys } from "react-hotkeys-hook"
import { infer as zInfer } from "zod"

import {
  getGooglePlaceDetailsClientSide,
  getGooglePlacesClientSide,
} from "@/lib/client-api/google.client"
import { Button } from "@/components/ui/button"
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

import { ConsulationFormSchema } from "./ConsultationForm"

interface PropsIf {
  form: UseFormReturn<zInfer<typeof ConsulationFormSchema>>
  field: ControllerRenderProps<zInfer<typeof ConsulationFormSchema>, "address">
  propStyle?: string
}

export default function FormAddressInput({ field, form, propStyle }: PropsIf) {
  const [open, setOpen] = useState(false)
  const [address, setAddress] = useState("")
  const [loading, setLoading] = useState(false)
  const [predictions, setPredictions] = useState<PlaceAutocompleteResult[]>([])
  const [highlight, setHighlight] = useState<PlaceAutocompleteResult | null>(
    null
  )

  useEffect(() => {
    if (address.length > 0) {
      if (predictions.length === 0) setLoading(true)

      const delayDebounceFn = setTimeout(loadPredictions, 200)
      return () => clearTimeout(delayDebounceFn)
    } else {
      setLoading(false)
    }
  }, [address])

  const loadPredictions = useCallback(async () => {
    if (predictions.length === 0) setLoading(true)

    const { predictions: updated } = await getGooglePlacesClientSide({
      search: address,
    })
    setPredictions(updated)
    setLoading(false)
    if (updated.length > 0) {
      setHighlight(updated[0])
    }
  }, [address, predictions])

  useHotkeys(
    "down",
    (e) => {
      if (predictions.length > 0 && open) {
        setHighlight((current) =>
          getNextOrPreviousPlace(
            predictions,
            current ? current.place_id : predictions[0].place_id,
            "down"
          )
        )
        e.preventDefault()
      }
    },
    { enableOnFormTags: true },
    [predictions, highlight, open]
  )

  useHotkeys(
    "up",
    (e) => {
      if (predictions.length > 0 && open) {
        setHighlight((current) =>
          getNextOrPreviousPlace(
            predictions,
            current ? current.place_id : predictions[0].place_id,
            "up"
          )
        )
        e.preventDefault()
      }
    },
    { enableOnFormTags: true },
    [predictions, highlight, open]
  )

  useHotkeys(
    "enter",
    (e) => {
      if (open) {
        if (highlight) handleSelect(highlight)
        e.preventDefault()
      }
    },
    { enableOnFormTags: true },
    [predictions, highlight, open]
  )

  // useEffect(() => {
  //   if (!open && predictions.length > 0) {
  //     handleSelect(predictions[0], false)
  //   }
  // }, [open, predictions])

  async function handleSelect(item: PlaceAutocompleteResult, update = true) {
    setLoading(true)
    const { result }: { result: Place } = await getGooglePlaceDetailsClientSide(
      { place_id: item.place_id }
    )
    if (result.formatted_address && result.address_components) {
      if (update) {
        field.onChange({
          target: {
            value: result.formatted_address,
          },
        })
      }

      let street = ""
      let route = ""
      let city = ""
      let state = ""
      let zip = ""
      let address = ""

      result.address_components.forEach((comp: any) => {
        if (comp.types.includes("street_number")) street = comp.long_name
        if (comp.types.includes("route")) route = comp.long_name
        if (comp.types.includes("locality")) city = comp.long_name
        if (comp.types.includes("administrative_area_level_1"))
          state = comp.short_name
        if (comp.types.includes("postal_code")) zip = comp.long_name
      })

      form.clearErrors("addressObj")

      address = [street, route].join(" ").trim()

      form.setValue("addressObj", { address, city, state, zip })
    }

    setOpen(false)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  function getNextOrPreviousPlace(
    places: PlaceAutocompleteResult[],
    highlightPlaceId: string,
    direction: "up" | "down"
  ): PlaceAutocompleteResult | null {
    const currentIndex = places.findIndex(
      (place) => place.place_id === highlightPlaceId
    )
    if (currentIndex === -1) {
      return places[0]
    }

    let newIndex: number

    if (direction === "up") {
      newIndex = (currentIndex - 1 + places.length) % places.length
    } else {
      newIndex = (currentIndex + 1) % places.length
    }

    return places[newIndex]
  }

  return (
    <FormItem className="col-span-1">
      <FormLabel className={propStyle} >Address</FormLabel>
      <Popover open={open} modal={true}>
        <PopoverTrigger asChild>
          <FormControl>
            <Input
              type={"address"}
              required
              placeholder="enter your address *"
              {...field}
              onClick={(e) => {
                e.preventDefault()
              }}
              onFocus={(e) => {
                e.preventDefault()
              }}
              onChange={(e) => {
                setAddress(e.target.value)
                setOpen(true)
                field.onChange(e)
              }}
            />
          </FormControl>
        </PopoverTrigger>
          <PopoverContent
            align="start"
            className="w-fit text-carbon-400 text-sm p-0 max-w-[90vw] rounded"
            onOpenAutoFocus={(e) => {
              e.preventDefault()
            }}
          >
            {form.getFieldState("addressObj").error && (
              <p className="flex gap-2 items-center font-medium text-sm px-4 py-2 border-b border-carbon-100 text-red-500 bg-red-50">
             Required
            </p>
            )}
            <p className="flex gap-2 items-center font-medium text-sm px-4 py-2 border-b border-carbon-100">
              Select an address from the list.
            </p>
            {loading ? (
              <p className="flex gap-2 items-center font-medium p-4">
                Loading... <Loader2 className="size-4 animate-spin" />
              </p>
            ) : address.length === 0 ? (
              <></>
            ) : predictions.length === 0 ? (
              <p className="flex gap-2 items-center font-medium p-4">
                No address found... <MapPin className="size-4" />
              </p>
            ) : (
              <div className="flex flex-col">
                {predictions.map((prediction) => {
                  return (
                    <Button
                      className="justify-start  h-fit max-h-fit text-left py-3"
                      key={prediction.place_id}
                      onClick={() => {
                        handleSelect(prediction)
                      }}
                      onMouseOver={() => setHighlight(prediction)}
                      variant={
                        highlight?.place_id === prediction.place_id
                          ? "secondary"
                          : "ghost"
                      }
                    >
                      {prediction.description}
                    </Button>
                  )
                })}
              </div>
            )}
          </PopoverContent>
      </Popover>
      <FormMessage></FormMessage>
      {form.getFieldState("addressObj").error && (
        <FormMessage>Please select a valid address.</FormMessage>
      )}
    </FormItem>
  )
}
