import React, { useEffect } from "react"
import { SiteConfig } from "@/config/site"
import Cookies from "js-cookie"

export default function IncludeTrackerArray({form}: {form: any}) {
  useEffect(() => {
    const trackerArray = JSON.parse(window.localStorage.getItem(SiteConfig.localStorage.trackerArray) || '[]')
    if(trackerArray.length < 1){
      const trackerIdCookie = Cookies.get(SiteConfig.cookies.trackerId)
      if(trackerIdCookie) {
        trackerArray.push({id: +trackerIdCookie, created_at: new Date()})
      }
    }
    form.setValue('trackerArray', trackerArray)
  }, [])

  return <></>
}