export async function getGooglePlacesClientSide({
  search,
}: {
  search: string
}) {
  const res = await fetch("/api/google/places-autocomplete", {
    method: "POST",
    body: JSON.stringify({ search }),
  })

  return await res.json()
}

export async function getGooglePlaceDetailsClientSide({
  place_id,
}: {
  place_id: string
}) {
  const res = await fetch("/api/google/place-details", {
    method: "POST",
    body: JSON.stringify({ place_id }),
  })

  return await res.json()
}
